var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.i18n)?_c('div',{staticClass:"commune-record-single"},[_c('breadcrumb',{attrs:{"content":_vm.breadcrumb}}),(_vm.data)?_c('div',{staticClass:"wrapper"},[(_vm.i18nSuivi)?_c('div',[_c('horizontal-timeline',{attrs:{"parentData":_vm.data}}),[(_vm.data.numEtape === _vm.ONE)?_c('div',[_c('step-one',{attrs:{"parentData":_vm.data}})],1):_vm._e()],[(_vm.data.numEtape === _vm.TWO)?_c('div',[_c('step-two',{attrs:{"parentData":_vm.data}})],1):_vm._e()],[(_vm.data.numEtape === _vm.THREE)?_c('div',[_c('step-three',{attrs:{"parentData":_vm.data}})],1):_vm._e()],[(_vm.data.numEtape === _vm.FOUR)?_c('div',[_c('step-four',{attrs:{"parentData":_vm.data}})],1):_vm._e()],[(_vm.data.numEtape === _vm.FIVE)?_c('div',[_c('step-five',{attrs:{"parentData":_vm.data}})],1):_vm._e()],[(_vm.data.numEtape === _vm.SIX)?_c('div',[_c('step-six-commune',{attrs:{"parentData":_vm.data}})],1):_vm._e()],[(_vm.data.numEtape === _vm.SEVEN)?_c('div',[_c('step-seven-commune',{attrs:{"parentData":_vm.data}})],1):_vm._e()],[(_vm.data.numEtape === _vm.EIGHT)?_c('div',[_c('step-eight-commune',{attrs:{"parentData":_vm.data}})],1):_vm._e()],[(_vm.data.numEtape === _vm.NINE)?_c('div',[_c('step-nine',{attrs:{"parentData":_vm.data}})],1):_vm._e()]],2):_vm._e(),_c('CustomLink',{staticClass:"mb-l mt-s",attrs:{"content":{
        label: _vm.i18n.address.backHome,
        to: { name: 'CommuneRecord' },
      },"modifiers":[
        'btn',
        'green',
        'white',
        'big',
        'rectrounded',
        'uppercase' ]}})],1):_c('g-loader',{attrs:{"modifiers":['big']}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }