

















































































/* eslint-disable */
import {useActions, useGetters, useState} from '@u3u/vue-hooks'
import {defineComponent, onBeforeMount, ref} from '@vue/composition-api'
import Breadcrumb from '@/components/Breadcrumb.vue'
import CustomLink from '@/components/g/CustomLink.vue'
import Axios from 'axios'
import {getApiUrl} from '@/inc/app.config'

const HorizontalTimeline = () => import('@/components/HorizontalTimeline.vue')
const StepTwo = () => import('@/components/my/dossier/StepTwo.vue')
const StepOne = () => import('@/components/my/dossier/StepOne.vue')
const StepThree = () => import('@/components/my/dossier/StepThree.vue')
const StepFour = () => import('@/components/my/dossier/StepFour.vue')
const StepFive = () => import('@/components/my/dossier/StepFive.vue')
const StepSix = () => import('@/components/my/dossier/StepSix.vue')
const StepSixCommune = () => import('@/components/my/dossier/StepSixCommune.vue')
const StepSevenCommune = () => import('@/components/my/dossier/StepSevenCommune.vue')
const StepEightCommune = () => import('@/components/my/dossier/StepEightCommune.vue')
const StepSeven = () => import('@/components/my/dossier/StepSeven.vue')
const StepEight = () => import('@/components/my/dossier/StepEight.vue')
const StepNine = () => import('@/components/my/dossier/StepNine.vue')

export default defineComponent({
  name: 'commune-record-single',
  components: {
    HorizontalTimeline,
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
    StepFive,
    StepSix,
    StepSeven,
    StepEight,
    StepNine,
    StepSixCommune,
    StepEightCommune,
    StepSevenCommune,
    Breadcrumb,
    CustomLink,
  },

  setup(props, ctx) {
    const {$route} = ctx.root
    const {id} = $route.params
    const {i18nSuivi} = useState('my', ['i18nSuivi'])
    const {currentLang} = useGetters(['currentLang'])
    const {i18n} = useState('commune', ['i18n'])
    const breadcrumb = [
      {
        label: i18n.value.departement.racc,
        url: `/${currentLang.value}/records/`,
      },
      {label: id, url: ''},
    ]

    const ONE = ref('1')
    const TWO = ref('2')
    const THREE = ref('3')
    const FOUR = ref('4')
    const FIVE = ref('5')
    const SIX = ref('6')
    const SEVEN = ref('7')
    const EIGHT = ref('8')
    const NINE = ref('9')

    const data = ref()
    const {fetchI18n} = useActions('my', ['fetchI18n'])

    onBeforeMount(async () => {
      fetchI18n().then(resp => record(id))
    })

    const record = id => {
      Axios.get(`${getApiUrl()}/town/works/records/${id}`).then(resp => data.value = resp.data)
          .catch(() => {
            ctx.root.$emit('popup:record:error')
            ctx.root.$router.push({name: 'CommuneRecord', params: {data: 'errorRecord'}})
          })
    }

    return {
      id,
      i18n,
      data,
      i18nSuivi,
      ONE,
      TWO,
      THREE,
      FOUR,
      FIVE,
      SIX,
      SEVEN,
      EIGHT,
      NINE,
      breadcrumb,
    }
  },
})
